@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.6px);
  }
}

body {
  @apply text-foreground-1;
  -webkit-tap-highlight-color: transparent;
}

// 去掉浏览器焦点框
* {
  outline: none;
}
*::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
}
*::-webkit-scrollbar-thumb {
  border-radius: 7px !important;
  background: #2d2c2c !important;
  box-shadow: inset 0 0 5px #6b6d6c !important;
  opacity: 0.5;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-corner {
  opacity: 0;
  background-color: transparent;
}
*::-webkit-scrollbar-button {
  height: 0;
  opacity: 0;
  background-color: transparent;
}

// 浏览器记住密码样式覆盖
input {
  &:-webkit-autofill {
    -webkit-text-fill-color: #ededed !important;
    box-shadow: 0 0 0px 1000px transparent inset !important;
    background-color: transparent !important;
    background-image: none !important;
    transition: background-color 50000s ease-in-out 0s !important; //背景色透明 生效时长 过渡效果 启用时延迟的时间
  }

  &::-webkit-input-placeholder {
    font-weight: 400 !important;
  }

  &[type='number'] {
    appearance: textfield;
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.code_ui_icon.code_ui_icon-spin {
  @apply animate-spin;
}

.fixed-page {
  padding-left: calc(100vw - 100%);
  @apply fixed w-full h-full overflow-hidden;
}
